<template>
  <v-container fluid>
    <search-form @submit="handleSearchFormSubmit"/>
    <v-card class="mt-5">
      <v-card-title>
        <v-icon
          small
          color="white"
          class="elevation-6 pink lighten-1 pa-2 mr-3"
        >
          mdi-format-list-bulleted-square
        </v-icon>
        {{ $vuetify.lang.t('$vuetify.patient.list') }}
        <v-spacer></v-spacer>

        <v-btn
          class="mx-1"
          color="error"
          dark
          small
          @click="handleDeleteClick"
        >
          {{ $vuetify.lang.t('$vuetify.common.delete') }}
        </v-btn>

        <v-btn
          :to="{ name: 'patientCreate'}"
          class="mx-1"
          color="primary"
          dark
          small
        >
          {{ $vuetify.lang.t('$vuetify.common.create') }}
        </v-btn>

        <v-btn
          :to="{ name: 'patientCreate', params: { patch: 'tab-single-sign' }}"
          class="mx-1"
          color="primary"
          dark
          small
        >
          {{ $vuetify.lang.t('$vuetify.patient.createSign') }}
        </v-btn>

        <v-btn
          :to="{ name: 'patientCreate', params: { patch: 'tab-batch-create' }}"
          class="mx-1"
          color="primary"
          dark
          small
        >
          {{ $vuetify.lang.t('$vuetify.common.batchCreate') }}
        </v-btn>

        <v-btn
          :to="{ name: 'patientCreate', params: { patch: 'tab-batch-sign' }}"
          class="mx-1"
          color="primary"
          dark
          small
        >
          {{ $vuetify.lang.t('$vuetify.patient.batchCreateSign') }}
        </v-btn>

      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="patientItems"
          item-key="id"
          show-select
          single-select
          hide-default-footer
        >

          <template
            v-slot:item.patientName="{ item }"
          >
            <router-link :to="`/patient/show/${item.id}`" >
              {{item.patientName}}
            </router-link>
          </template>

          <template
            v-slot:item.gender="{item}"
          >
            {{ $vuetify.lang.t(`$vuetify.gender.${item.gender.toLowerCase()}`) }}
          </template>

          <template
            v-slot:item.age="{item}"
          >
            {{  item.age }} {{ $vuetify.lang.t('$vuetify.sosAlarm.age') }}
          </template>

          <template
            v-slot:item.address="{item}"
          >
            {{ item.address.replaceAll(',', '-') }}
          </template>

           <template
            v-slot:item.institution="{item}"
          >
            <span
              v-if="item.status === 'UNSIGNED'"
            >
              -
            </span>
            <span
              v-else
            >
              {{ item.institutionName }}
            </span>
          </template>

          <template
            v-slot:item.contract="{item}"
          >
            <span
              v-if="item.status === 'UNSIGNED'"
            >
              <router-link :to="{ name:'patientSign', params: { id: item.id + '' }}" >
                {{ $vuetify.lang.t(`$vuetify.patient.sign`) }}
              </router-link>
            </span>
            <span
              v-else
            >
              {{ $vuetify.lang.t(`$vuetify.signStatus.${item.status.toLowerCase()}`) }}
            </span>
          </template>

           <template
            v-slot:item.deviceId="{item}"
          >
            <span
              v-if="item.deviceId"
            >
              <router-link :to="{ name:'equipmentChange', params: { id: item.equId + '' , patientId: item.id  + '' }}" >
                {{ item.deviceId }}
              </router-link>
            </span>
            <span
              v-else
            >
              -
            </span>
          </template>

          <template
            v-slot:item.actions="{ item }"
          >

            <v-btn
              color="info"
              x-small
              text
              fab
              @click="handleEditItem(item)"
            >
              <v-icon
                small
              >
              mdi-pencil
              </v-icon>
            </v-btn>

            <v-btn
              v-if="item.deviceId"
              color="info"
              x-small
              text
              fab
              @click="handleShowReportItem(item)"
            >
              <v-icon
                small
              >
              mdi-file
              </v-icon>
            </v-btn>

            <v-btn
              v-if="item.deviceId"
              color="info"
              x-small
              text
              fab
              @click="handleShowDataItem(item)"
            >
              <v-icon
                small
              >
              mdi-chart-areaspline-variant
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-pagination
          v-model="queryParams.params.page"
          :length="queryParams.params.pageCount"
        />
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $vuetify.lang.t('$vuetify.common.warning') }}
        </v-card-title>
        <v-card-text
          v-if="selected.length > 0"
        >
          {{ $vuetify.lang.t('$vuetify.message.deleteConfirm') }}
          <Strong>
            {{ selected[0].name }}
          </Strong>
          ?
        </v-card-text>
        <v-card-text
          v-else
        >
          {{ $vuetify.lang.t('$vuetify.message.needSelectedOne') }}
        </v-card-text>
        <v-card-actions
          v-if="selected.length > 0"
        >
          <v-spacer></v-spacer>
          <v-btn
            color="info darken-1"
            @click="deleteDialog = false"
          >
            {{ $vuetify.lang.t('$vuetify.common.cancel') }}
          </v-btn>
          <v-btn
            color="error darken-1"
            @click="handleDeleteDialog"
          >
            {{ $vuetify.lang.t('$vuetify.common.delete') }}
          </v-btn>
        </v-card-actions>
        <v-card-actions
          v-else
        >
          <v-spacer></v-spacer>
          <v-btn
            color="info darken-1"
            @click="deleteDialog = false"
          >
            {{ $vuetify.lang.t('$vuetify.common.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      SearchForm: () => import('./SearchForm')
    },
    data () {
      return {
        patientItems: [],
        selected: [],
        editItem: null,
        deleteDialog: false,
        queryParams: {
          params: {
            page: 1,
            pageCount: 1
          }
        }
      }
    },

    computed: {
      ...mapGetters({
        patients: 'patient/patients'
      }),
      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.patient.name'),
            align: 'start',
            sortable: false,
            value: 'patientName',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.gender'),
            align: 'start',
            sortable: false,
            value: 'gender',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.age'),
            align: 'start',
            sortable: false,
            value: 'age',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.address'),
            align: 'start',
            sortable: false,
            value: 'address',
          },{
            text: this.$vuetify.lang.t('$vuetify.patient.institution'),
            align: 'start',
            sortable: false,
            value: 'institution',
          },{
            text: this.$vuetify.lang.t('$vuetify.contract.label'),
            align: 'start',
            sortable: false,
            value: 'contract',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipment.deviceId'),
            align: 'start',
            sortable: false,
            value: 'deviceId',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.actions'),
            align: 'start',
            sortable: false,
            value: 'actions',
          }
        ]
      }
    },

    watch: {
      'queryParams.params.page': {
         handler: function(newValue) {
            let params = Object.assign({}, this.queryParams.params)
            params.page = newValue - 1
            this.patientQuery({params: params, data: this.queryParams.data }).then( (res) => {
              this.queryParams.params.pageCount = res.totalPages
              this.patientItems = this.patients
            })
         }
      }
    },

    created () {
      let params = Object.assign({}, this.queryParams.params)
      params.page--
      this.patientQuery({
        params: params,
        data: {}
      }).then( (res) => {
        this.queryParams.params.pageCount = res.totalPages
        this.patientItems = this.patients
      })
    },

    methods: {
      ...mapActions({
        patientQuery: 'patient/query',
        patientDelete: 'patient/delete'
      }),

      handleSearchFormSubmit(data) {
        this.queryParams.data = data
        let params = Object.assign({}, this.queryParams.params)
        params.page = 0
        this.patientQuery({params: params, data }).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
          this.patientItems = this.patients
        })
      },

      handleDeleteClick () {
        this.deleteDialog = true
      },

      handleDeleteDialog () {
        this.patientDelete(this.selected[0]).then( () => {
          this.deleteDialog = false
          this.patientItems = this.patients
        })
      },

      handleShowItem () {
        console.log()
      },

      handleEditItem (item) {
        this.$router.push( { name: 'patientEdit', params: { id: item.id + ''} } )
      },

      handleShowReportItem (item) {
         this.$router.push( { name: 'patientHealthReports', params: { patientId: item.id + ''} } )
      },

      handleShowDataItem (item) {
         this.$router.push( { name: 'patientHealthDatas', params: { patientId: item.id + ''} } )
      },
    }
  }
</script>
